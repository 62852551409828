import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { SearchBarJP, SearchPropsJP } from '@/components';
import { DefaultOptionType } from 'antd/lib/select';
import { useNavigate } from 'react-router-dom';
import { type MenuProps } from 'antd';
import _ from 'lodash';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchCache } from '@/stores/reducers/searchJp';
import { t } from '@/core/hooks/useTranslation';
import { useSearchParams } from 'react-router-dom';
import { StarOutlined } from '@ant-design/icons';
import icon from '@/assets/icons';
import { useGetCategoryList } from '@/hooks/userJp.hook';
import { setMenuData } from '@/stores/reducers/userJP';
import { useGetMuteAllPermissions } from '@/hooks/permission.hook';
import Nav from './Nav';
import { logoutGigya } from '@/core/utils';
import { user } from '@/services/user.service';
import { setUserPermissions } from '@/stores/reducers/user';
import { getuser } from '@/services/userJp.service';

import head from 'lodash/head';
import get from 'lodash/get';
import { myLoginService } from '@/services/login.service';

const constMenuItems: any = (): MenuProps['items'] => [
	{
		label: t('HomeJp.Home'),
		key: '/home',
	},
	{
		label: t('HomeJp.Notification'),
		key: '/notification',
	},
	{
		label: t('HomeJp.Products'),
		key: '/products',
	},
	{
		label: t('HomeJp.Service'),
		key: '/service',
	},
	{
		label: t('HomeJp.Help'),
		key: '/help',
	},
	{
		label: t('HomeJp.Admin'),
		key: '/admin',
	},
];

const constQuickLinks = (): MenuProps['items'] | any => [
	{
		key: 'FT-IR',
		label: t('QuickLink.ftir'),
		children: [
			{
				key: 'LINK:https://www.thermofisher.com/jp/ja/home/industrial/spectroscopy-elemental-isotope-analysis/molecular-spectroscopy.html',
				label: t('QuickLink.webProductPage'),
			},
			{
				key: 'LINK:https://www.thermofisher.com/jp/ja/home/about-us/events.html',
				label: t('QuickLink.eventInformation'),
			},
			{
				key: 'LINK:https://www.thermofisher.com/jp/ja/home/technical-resources/e-learnings/ft-ir-raman.html',
				label: t('QuickLink.eLearning'),
			},
			{
				key: 'LINK:https://www.thermofisher.com/blog/learning-at-the-bench/',
				label: t('QuickLink.blog'),
			},
			{
				key: '/form-inquiry-FTIR',
				label: t('QuickLink.formsInquiryContact'),
			},
			{
				key: 'LINK:https://forms.office.com/r/mNmusTC85u',
				label: t('QuickLink.Catalog_request_contact_page'),
			},
		],
	},
	{
		key: 'UV-Vis',
		label: t('QuickLink.uvvis'),
		children: [
			{
				key: 'LINK:https://www.thermofisher.com/jp/ja/home/industrial/spectroscopy-elemental-isotope-analysis/molecular-spectroscopy/ultraviolet-visible-visible-spectrophotometry-uv-vis-vis.html',
				label: t('QuickLink.webProductPage'),
			},
			{
				key: 'LINK:https://www.thermofisher.com/blog/learning-at-the-bench',
				label: t('QuickLink.blog'),
			},
			{
				key: '/form-inquiry-UVVIS',
				label: t('QuickLink.formsInquiryContact'),
			},
			{
				key: 'LINK:https://forms.office.com/r/mNmusTC85u',
				label: t('QuickLink.Catalog_request_contact_page'),
			},
		],
	},
];

export const Header = () => {
	const navigate = useNavigate();
	const searchCache = useSelector<any>(
		(statusbar) => statusbar?.searchJp?.searchCache
	);

	const dispatch = useDispatch();

	const isClearSearchVal = useRef(false);
	const [searchParams] = useSearchParams();
	const value = searchParams.get('searchVal');
	const [MenuItems, setMenuItems] = useState<any[]>(constMenuItems());
	const [autoVal, setAutoVal] = useState<DefaultOptionType['value']>('');
	const [headerIsFixed, setHeaderIsFixed] = useState<boolean>(false);
	const [selectedKeys, setSelectedKeys] = useState(['/']);
	const [quickLinks, setQuickLinks] = useState<any[]>([]);
	const lang = useSelector<any>((state) => state?.user?.lang) as any;
	const userId =
		useSelector<any>((status) => status?.user?.userBo?.id) ||
		(user?.getUserBo() && JSON.parse(user?.getUserBo() as string)?.id) ||
		'';
	const { mutateAsync: fetchAllPermissions } = useGetMuteAllPermissions();

	const { data: navList } = useGetCategoryList({
		categoryType: 4,
	});
	useEffect(() => {
		if (navList?.code == '000000' && navList?.data) {
			dispatch(setMenuData(navList?.data));
			initQuickLink(navList?.data);
			const productLineId =
				getuser.getProductLine() || get(head(navList?.data), 'id');
			getuser.setProductLine(productLineId || '');
		}
	}, [navList, lang]);

	useEffect(() => {
		toFetchUserPermissions();
	}, [window.location.pathname, lang]);

	const initQuickLink = (items: any[]) => {
		const arrs: any[] = [];
		_.forEach(items, (item) => {
			if (item?.id == 1 || item?.categoryName.indexOf('FT-IR') > -1) {
				arrs.push({
					key: 'FT-IR',
					label: item?.categoryName,
					children: constQuickLinks()?.find((res: any) => res?.key == 'FT-IR')
						?.children,
				});
			} else if (item?.id == 2 || item?.categoryName?.indexOf('UV-Vis') > -1) {
				arrs.push({
					key: 'UV-Vis',
					label: item?.categoryName,
					children: constQuickLinks()?.find((res: any) => res?.key == 'UV-Vis')
						?.children,
				});
			}
		});
		setQuickLinks(arrs);
	};

	const toFetchUserPermissions = () => {
		fetchAllPermissions(userId).then((per: any) => {
			dispatch(setUserPermissions(per?.data));
			user.setUserPermissions(JSON.stringify(per?.data));
			initPermissionsOfAdmin(per?.data);
		});
	};

	const initPermissionsOfAdmin = (obj: any) => {
		let menuList = constMenuItems();
		if (!obj?.adminShow) {
			const elementToRemove: any = menuList?.find(
				(item: any) => item?.key === '/admin'
			);
			if (elementToRemove) {
				menuList = menuList?.filter((item: any) => item !== elementToRemove);
			}
		}
		setMenuItems(menuList);
	};

	const logout = async () => {
		await logoutGigya();
		await myLoginService.removeTokenData();
		await myLoginService.removeGigyaToken();
		await getuser.removeProductLine();
	};

	const accountData: MenuProps['items'] = [
		{
			label: (
				<div className='flex items-center whitespace-nowrap'>
					<img src={`${icon?.peopleLogo}`} className='mr-3' />
					{t('LoginJp.My_profile')}
				</div>
			),
			key: '/my-profile',
		},
		{
			label: (
				<div className='flex items-center whitespace-nowrap'>
					<StarOutlined className='mr-3 text-[1.4rem]' />
					{t('LoginJp.My_favorite')}
				</div>
			),
			key: '/my-favorite',
		},
		{
			label: (
				<div
					className='flex items-center whitespace-nowrap ml-[0.2rem]'
					onClick={logout}
				>
					<img src={`${icon?.logoutLogo}`} className='mr-3' />
					<div className='ml-[-0.03rem]'>{t('LoginJp.Logout')}</div>
				</div>
			),
			key: '',
		},
	];

	useEffect(() => {
		const pathSegments = _.filter(
			_.split(window.location.pathname, '/'),
			Boolean
		);
		const rootPath =
			_.size(pathSegments) > 0 ? `/${_.head(pathSegments)}` : '/';
		const isPathExist = _.some(
			constMenuItems(),
			(item) => item.key === rootPath
		);

		if (isPathExist) {
			setSelectedKeys([rootPath]);
		} else {
			setSelectedKeys([]);
		}
	}, [window.location.pathname]);

	useEffect(() => {
		setAutoVal(value);
	}, [value]);

	const onSubmit = useCallback<Exclude<SearchPropsJP['onSubmit'], undefined>>(
		(val: any) => {
			if (!val || _.size(val) <= 1) return;
			dispatch(
				setSearchCache({
					item: val,
				})
			);

			navigate(`/searchResult?${encodeURI(`searchVal=${val}`)}`, {
				replace: true,
			});
			isClearSearchVal.current = true;
		},
		[]
	);

	const autoCompleteOnchange = useCallback<
		Exclude<SearchPropsJP['autoCompleteOnchange'], undefined>
	>((val) => {
		setAutoVal(val);

		if (!val || val.length < 2) return;
	}, []);

	const completeClick = useCallback((res: any) => {
		dispatch(
			setSearchCache({
				item: res?.item,
			})
		);

		navigate(`/searchResult?${encodeURI(`searchVal=${res?.item}`)}`);

		isClearSearchVal.current = true;
	}, []);

	const autoCompleteDefaultOptions = useMemo<
		SearchPropsJP['autoCompleteDefaultOptions']
	>(() => {
		const data: any = searchCache;
		if (!data || !data?.length || autoVal) return undefined;

		return [
			{
				label: t('SearchJp.searchHistory'),
				options: _.map(data, (res: any, index: number) => ({
					label: (
						<div onClick={() => completeClick(res)} key={index + 1}>
							<p>{res?.item}</p>
						</div>
					),
					value: res?.item,
				})),
			},
		];
	}, [searchCache, autoVal, lang]);

	const autoCompleteOptions = useMemo<
		SearchPropsJP['autoCompleteOptions']
	>(() => {
		const data: any = searchCache;
		if (!data || !data?.length || !autoVal) return autoCompleteDefaultOptions;

		return _.map(data, (res: any, index) => ({
			label: (
				<div onClick={() => completeClick(res)} key={index + 1}>
					<p>{res && res?.item}</p>
				</div>
			),
			value: res && res?.item,
		}));
	}, [searchCache, autoVal, completeClick, autoCompleteDefaultOptions]);

	const navItemOnClick = useCallback<Exclude<MenuProps['onClick'], undefined>>(
		(v) => {
			setAutoVal('');
			navigate(v?.key);
			setSelectedKeys([v?.key]);
		},
		[]
	);

	const headerFixed = useCallback(() => {
		const isFixed = document.documentElement.scrollTop > 160;
		setHeaderIsFixed(isFixed);

		document.documentElement.style.marginTop = isFixed ? '140px' : '0';
	}, []);

	const accountClick = useCallback<Exclude<MenuProps['onClick'], undefined>>(
		(val) => {
			setAutoVal('');
			navigate(val.key);
		},
		[]
	);

	const clickLink = useCallback<Exclude<MenuProps['onClick'], undefined>>(
		(val: any) => {
			if (val.key.startsWith('LINK:')) {
				const link = val?.key?.replace('LINK:', '');
				window.open(link, '_blank');
			} else {
				navigate(val?.key);
			}
		},
		[]
	);

	useEffect(() => {
		document.addEventListener('scroll', headerFixed);

		return () => {
			document.removeEventListener('scroll', headerFixed);
		};
	}, []);
	return (
		<div
			className={cn(
				'flex justify-center shadow-lg bg-white',
				headerIsFixed ? 'fixed top-0 left-0 w-full z-50' : ''
			)}
		>
			<div className='px-8 pb-5 w-full'>
				<Nav
					navItems={MenuItems}
					navItemOnClick={navItemOnClick}
					selectedKeys={selectedKeys}
					accountItems={accountData}
					accountClick={accountClick}
					clickLink={clickLink}
					quickLink={quickLinks}
				/>
				<SearchBarJP
					autoCompleteDefaultOptions={autoCompleteDefaultOptions}
					setAutoVal={setAutoVal}
					autoVal={autoVal}
					onSubmit={onSubmit}
					autoCompleteOnchange={_.debounce(autoCompleteOnchange, 300)}
					autoCompleteOptions={autoCompleteOptions}
					isClearSearchVal={isClearSearchVal.current}
					clearValCallback={() => (isClearSearchVal.current = false)}
				/>
			</div>
		</div>
	);
};
